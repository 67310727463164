<template>
	<div>
		<div class="row">
			<div v-for="q in questions" :key="q.id" class="col-lg-4">
				<div class="mb-3">
					<strong>{{ q.name }}</strong>
				</div>
				<a-student-questionnaire-option v-for="ch in q.question_choices" :key="ch.id" class="mb-2" :option="ch" :checked="isChecked(q.id, ch.id)" @click="onAnswerSelected(q.id, ch.id)" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		'a-student-questionnaire-option': () => import('@/plugins/app@user/register/components/a-student-questionnaire-option.vue')
	},

	props: {
		questions: {
			type: Array,
			required: true
		},
		value: {
			type: Array,
			required: true
		}
	},

	methods: {
		onAnswerSelected(questionId, questionChoiceId) {
			const answers = [...this.value]
			const index = answers.findIndex((a) => a.question_id == questionId)

			if (index == -1) answers.push({ question_id: questionId, question_choice_id: questionChoiceId })
			else answers[index].question_choice_id = questionChoiceId

			this.$emit('input', answers)
		},

		isChecked(questionId, questionChoiceId) {
			const answer = this.value.find((a) => a.question_id == questionId)

			if (!answer) return false
			return answer.question_choice_id == questionChoiceId
		}
	}
}
</script>
